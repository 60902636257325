import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import router from "../../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async id => {
	try {
		const response = await axios.get(`/coring-campaigns/${id}`);
		return response.data;
	}
	catch (error){
		return error.response;
	}
};

const create = async campaign => {
	try {
		const response = await axios.post("/coring-campaigns", campaign);

		toast("success", "Campagne créée !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer la campagne : " +
      router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const edit = async(id, campaign) => {
	try {
		const response = await axios.put(`/coring-campaigns/${id}`, campaign);

		toast("success", "Campagne modifiée !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

const editStatus = async(id, status) => {
	try {
		const response = await axios.patch(`/coring-campaigns/${id}`, {status});

		toast("success", "Statut modifié !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le statut" + getMessage(error.response.status)
		);
		throw error;
	}
};

const sendToProvider = async(id, data) => {
	try {
		const response = await axios.post(
			`/coring-campaigns/${id}/actions/send-to-provider`,
			data,
			{showLoader: false}
		);

		toast("success", "Campagne envoyée au prestataire !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'envoyer les informations au prestataire" +
      getMessage(error.response.status)
		);
		throw error;
	}
};

const getFromProvider = async(id, skipApiOnError = false) => {
	try {
		const response = await axios.post(
			`/coring-campaigns/${id}/actions/get-from-provider?skipApiOnError=${skipApiOnError}`,
			{showLoader: false}
		);

		if(true === skipApiOnError){
			toast("success", "Les PDF ont été regénérés.");
		}
		else {
			toast("success", "Informations récupérées");
		}

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les informations de la campagne : " + router.app.$t(error.response.data.message)
		);
		throw error;
	}
};

const getTemporaryPDF = async campaignId => {
	try {
		const response = await axios.get(`/coring-campaigns/${campaignId}/pdf`, {
			responseType: "arraybuffer",
			showLoader: false
		});

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le pdf" + getMessage(error.response.status)
		);
		throw error;
	}
};

const sendMailToEngineer = async(id, formData) => {
	try {
		const response = await axios.post(`/campaigns/${id}/contact`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
		toast("success", "e-mail envoyé avec succès à l'ingénieur");
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible d'envoyer les informations à l'ingenieur " + router.app.$t(error.response.data.message)
		);
		throw error;
	}
};

const getStructuralDashboard = async id => {
	try {
		const response = await axios.get(
			`/coring-campaigns/${id}/structural-dashboard`
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer le tableau" + getMessage(error.response.status)
		);
		throw error;
	}
};

const addPrestationsAndProvider = async(id, data) => {
	try {
		const response = await axios.put(
			`/coring-campaigns/${id}/add-prestations-and-providers`, data
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de générer le tableau" + getMessage(error.response.status)
		);
		throw error;
	}
};

const backFromSentPrestation = async(id) => {
	try {
		const response = await axios.delete(`/coring-campaigns/${id}/backFromSentPrestation`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer l'analyse" + getMessage(error.response.status)
		);
		throw error;
	}
};

const updateAccessCustomer = async(id, hasCustomerAccess) => {
	try {
		const response = await axios.put(`/coring-campaigns/${id}/update-customer-access`, {
			hasCustomerAccess: hasCustomerAccess
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour l'accès au client sur la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

const updateShouldValidateCustomer = async(id, shouldValidateCustomer) => {
	try {
		const response = await axios.put(`/coring-campaigns/${id}/should-validate-customer`, {
			shouldValidateCustomer: shouldValidateCustomer
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour l'accès au client sur la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

const updateValidateCustomer = async(id, validateCustomer) => {
	try {
		const response = await axios.put(`/coring-campaigns/${id}/update-validate-customer`, {
			validateCustomer: validateCustomer
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour l'accès au client sur la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

const deleteEurofinsPrestation = async(campaignId) => {
	try {
		const response = await axios.delete(`/coring-campaigns/${campaignId}/remove-eurofins-prestations`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de mettre à jour l'accès au client sur la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findById,
	create,
	edit,
	editStatus,
	sendToProvider,
	getFromProvider,
	getTemporaryPDF,
	sendMailToEngineer,
	getStructuralDashboard,
	addPrestationsAndProvider,
	backFromSentPrestation,
	updateAccessCustomer,
	updateShouldValidateCustomer,
	updateValidateCustomer,
	deleteEurofinsPrestation
};
